<template>
  <crud-form
    :titulo-pagina="tituloPagina"
    :is-editting="isEditting"
    :mensagem-pergunta-remocao="mensagemPerguntaRemocao"
    :mostra-botao-salvar-continuar="false"
    @deleteEvent="remover"
    @closeDialogEvent="closeDialog"
    @saveEvent="saveEventHandler"
  >
    <v-container class="py-0">
      <v-col cols="24">
        <v-row>
          <v-col cols="12">
            <empresa-cliente-auto-complete
              v-model="objEditar.empresaCliente"
              :readonly="isEditting"
              :clearable="!isEditting"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="objEditar.title"
              class="purple-input"
              :label="$t('ContratoForm.title')"
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="objEditar.idTipoEvento"
              :rules="[required]"
              :label="$t('ContratoForm.tipoEvento')"
              :items="tiposDeEventos"
              item-value="id"
              item-text="nomeTipoEvento"
              required
              prepend-icon="mdi-format-list-bulleted-type"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h4>Textos do contrato</h4>
            <TextoContratoSelector v-model="objEditar.textos" />
          </v-col>
        </v-row>
      </v-col>
    </v-container>
  </crud-form>
</template>
<script>
import ContratoService from "@/services/ContratoService";
import TipoEventoService from "@/services/TipoEventoService";
import TextoContratoService from "@/services/TextosContratoService";
import EmpresaClienteAutoComplete from "@/views/EmpresaClienteAutoComplete";

export default {
  components: { EmpresaClienteAutoComplete },
  data: () => ({
    objPadrao: {
      title: "",
      textos: [],
      idTipoEvento: null,
      empresaCliente: {},
      id: null
    },
    objEditar: {
      title: "",
      textos: [],
      idTipoEvento: null,
      empresaCliente: {},
      id: null
    },
    isEditting: true,
    tiposDeEventos: [],
    required: value => !!value || "Campo Obrigatório"
  }),
  watch: {
    objEditar: function() {
      this.preencheTipoEventos();
    }
  },
  computed: {
    mensagemPerguntaRemocao() {
      return this.$i18n.t("padrao_deseja_remover_registro");
    },
    tituloPagina() {
      return this.$i18n.t("ContratoForm.tituloPagina");
    }
  },
  created() {
    this.$root.$emit("alteraMostraPesquisa", false);
    let id = this.$route.params.id;
    if (id) {
      this.editar(id);
    } else {
      this.isEditting = false;
      this.objEditar = this.objPadrao;
    }
  },
  methods: {
    saveEventHandler() {
      if (this.isEditting) {
        this.atualizar(this.objEditar);
      } else {
        this.inserir(this.objEditar);
      }
    },
    atualizar(registro) {
      try {
        ContratoService.update(registro).then(response => {
          if (!response) {
            this.$root.$emit("mostra_dialogo_erro", {
              dialogoDescricao: "Deu algum erro,\nResposta do Servidor: null "
            });
          } else if (response.error) {
            this.$root.$emit("mostra_dialogo_erro", {
              dialogoDescricao: "" + response.error
            });
          } else {
            this.$root.$emit("mostra_dialogo_info", {
              dialogoTitulo: this.$t("padrao_sucesso"),
              dialogoDescricao: this.$t("padrao_registro_salvo_sucesso")
            });
            this.closeDialog();
          }
        });
      } catch (error) {
        this.$root.$emit("mostra_dialogo_erro", {
          dialogoDescricao:
            "Não foi possível concluir a atualização, tente mais tarde\n" +
            error
        });
      }
    },
    preencheTipoEventos() {
      this.tiposDeEventos = [];
      if (!this.objEditar.empresaCliente) {
        return;
      }
      TipoEventoService.findAll(null, this.objEditar.empresaCliente.id)
        .then(tiposDeEventos => {
          if (
            tiposDeEventos &&
            tiposDeEventos.data &&
            tiposDeEventos.data.rows
          ) {
            this.tiposDeEventos = tiposDeEventos.data.rows;
          }
        })
        .catch(error => {
          console.error(error);
          this.$root.$emit("mostra_dialogo_erro", {
            dialogoDescricao: "Não foi possível carregar os tipos de evento"
          });
        });
    },
    closeDialog() {
      this.$router.push("/contrato");
    },
    remover() {
      const contrato = this.objEditar;
      try {
        ContratoService.delete(contrato).then(response => {
          this.$root.$emit("mostra_dialogo_info", {
            dialogoTitulo: this.$t("padrao_sucesso"),
            dialogoDescricao: this.$t("padrao_registro_removido_sucesso")
          });
          this.closeDialog();
        });
      } catch (error) {
        this.$root.$emit("mostra_dialogo_erro", {
          dialogoDescricao:
            "Ocorreu um erro ao remover o contrato:\n ->" + error
        });
      }
    },
    inserir(contrato, manterNestaTela) {
      try {
        ContratoService.insert(contrato)
          .then(response => {
            if (!response) {
              this.$root.$emit("mostra_dialogo_erro", {
                dialogoDescricao: "Deu algum erro,\nResposta do Servidor: null "
              });
            } else if (response.error) {
              this.$root.$emit("mostra_dialogo_erro", {
                dialogoDescricao: "" + response.error
              });
            } else {
              this.$root.$emit("mostra_dialogo_info", {
                dialogoTitulo: this.$t("padrao_sucesso"),
                dialogoDescricao: this.$t("padrao_registro_salvo_sucesso")
              });
              if (manterNestaTela) {
                this.editar(contrato.data.id);
              } else {
                this.closeDialog();
              }
            }
          })
          .catch(error => {
            this.$root.$emit("mostra_dialogo_erro", {
              dialogoDescricao:
                "Ocorreu um erro ao inserir o contrato:\n ->" + error
            });
          });
      } catch (error) {
        this.$root.$emit("mostra_dialogo_erro", {
          dialogoDescricao:
            "Ocorreu um erro ao inserir o contrato:\n ->" + error
        });
      }
    },
    editar(id) {
      return ContratoService.findById({ id })
        .then(contrato => {
          if (contrato.id) {
            this.objEditar = contrato;
            this.isEditting = true;
          } else if (contrato.data.id) {
            this.objEditar = contrato.data;
            this.isEditting = true;
          } else {
            this.isEditting = false;
          }
        })
        .catch(() => {
          this.isEditting = false;
        });
    }
  }
};
</script>
